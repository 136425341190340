import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import {
  externalToInternal,
  feedFactoryAxios,
  FormData,
  Twemoji,
} from "helpers";
import { useParams } from "react-router-dom";
import { Lang, langMetadata } from "../../../models/Lang/Lang";
import {
  EntityType,
  ExternalItemModel,
  ExtProperty,
  InternalItemModel,
} from "../../../models/Ndtrc/Ndtrc";
import classes from "../ItemForm.module.scss";
import { HoverDesc } from "../../../components/HoverDesc/HoverDesc";
import { NdtrcAlias } from "../../../models/NdtrcAlias/NdtrcAlias";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import ReactQuill from "react-quill";
import PropertiesWidget from "../../../components/FormWidgets/PropertiesWidget/PropertiesWidget";
import { PermissionObject } from "../../../models/PermissionObject/PermissionObject";
import CategorySelectWidget from "../../../components/FormWidgets/CategorySelectWidget/CategorySelectWidget";
import MediaWidget from "../../../components/FormWidgets/MediaWidget";

const ItemFormAlias = (props: {
  readonly primaryLanguage: Lang;
  readonly originalItem: ExternalItemModel;
  readonly intItem: InternalItemModel;
  readonly onSubmit: (formData: FormData) => void;
  readonly formMethods: UseFormReturn;
  readonly localSaveTrigger: (extraData?: FormData | any) => any;
  readonly myPermissions: PermissionObject;
}) => {
  const { t } = useTranslation();
  const { version: version, id: itemId } =
    useParams<{ version: string; id: string }>();
  const formMethods = props.formMethods;
  const { register, setValue, getValues, reset, watch } = formMethods;

  const control: any = formMethods.control;
  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: `${version}.trcItem.trcItemCategories.categories`,
    keyName: "id",
  });

  const isCongressAlias = version === "congres";

  const aliasCategoriesId = `${version}.trcItem.trcItemCategories.categories`;
  const watchedAliasCategories: ExtProperty[] | null =
    formMethods.watch(aliasCategoriesId);

  const categoriesId = `ndtrcEntry.trcItemCategories.categories`;
  const watchedCategories: ExtProperty[] | null =
    formMethods.watch(categoriesId);

  const watchedAliasEntityType: EntityType = formMethods.watch(
    `${version}.trcItem.entitytype`
  );
  const watchedEntityType: EntityType = formMethods.watch(
    `ndtrcEntry.entitytype`
  );

  useEffect(() => {
    if (!props.intItem) {
      return;
    }

    // Download full item
    const urlStem =
      props.intItem.entitytype === "EVENEMENT"
        ? "events"
        : props.intItem.entitytype === "LOCATIE"
        ? "locations"
        : "eventgroups";
    const url = `/${t(urlStem)}/${props.intItem.id}/aliases`;
    feedFactoryAxios.get(url).then((res) => {
      const aliasItem: NdtrcAlias = res.data.find(
        (alias: NdtrcAlias) => alias.aliasTitle === version
      );
      if (!aliasItem) {
        console.warn("NO SUCH ALIAS");
        return;
      }

      register(version);

      // @ts-ignore
      aliasItem.trcItem = externalToInternal(aliasItem.trcItem);

      reset({
        [version]: { ...aliasItem },
        ndtrcEntry: { ...props.intItem },
      });
    });
  }, [version, props.intItem, props.primaryLanguage, reset, itemId]);

  return (
    <div className={"EventForm SecondaryLangForm"}>
      <form>
        {/* Submission happens in parent class on page change / save action */}
        <section>
          <div className={`comparisonView ${classes.versionHeader}`}>
            <h2>
              <Twemoji>{langMetadata[props.primaryLanguage]?.flag}</Twemoji>{" "}
              {t(`language.${props.primaryLanguage}`)} ({t("language.primary")})
            </h2>
            <h2 className={classes.capitalize}>
              <Twemoji>📗</Twemoji> {version}
            </h2>
          </div>

          {/*Title*/}
          <label>
            {t("event.title")}
            <HoverDesc description="event.title.desc" />
          </label>
          <div className={"comparisonView"}>
            <input
              {...register(
                `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].title`
              )}
              disabled
            />
            <input
              {...register(
                `${version}.trcItem.trcItemDetails[${props.primaryLanguage}].title`
              )}
            />
          </div>

          {/*Short description*/}
          <div className={"formSection"}>
            <label>
              {t("event.shortdescription")}
              <HoverDesc description="event.shortdescription.desc" />
            </label>
            <div className={"comparisonView"}>
              {/*Original*/}
              <textarea
                {...register(
                  `ndtrcEntry.trcItemDetails[${props.primaryLanguage}].shortdescription`
                )}
                disabled
              />

              {/*Alias*/}
              <div>
                <textarea
                  {...register(
                    `${version}.trcItem.trcItemDetails[${props.primaryLanguage}].shortdescription`
                  )}
                />
                <p className={`${classes.characterCount} ${classes.fullWidth}`}>
                  {t("form.characters")}:{" "}
                  {watch(
                    `${version}.trcItem.trcItemDetails[${props.primaryLanguage}].shortdescription`
                  )?.length || 0}
                </p>
              </div>
            </div>
          </div>

          {/*Long description*/}
          <div className={"formSection"}>
            <label>
              {t("event.longdescription")}
              <HoverDesc description="event.longdescription.desc" />
            </label>
            <div className={"comparisonView"}>
              {/*Original*/}
              <Controller
                name={`ndtrcEntry.trcItemDetails[${props.primaryLanguage}].longdescription`}
                control={props.formMethods.control}
                defaultValue={""}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <ReactQuill
                    readOnly
                    onChange={(value) => {
                      onChange(value);
                    }}
                    value={value}
                  />
                )}
              />

              {/*Alias*/}
              {isCongressAlias && (
                <div>
                  <Controller
                    name={`${version}.trcItem.trcItemDetails[${props.primaryLanguage}].longdescription`}
                    control={props.formMethods.control}
                    defaultValue={""}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <ReactQuill
                        onChange={(value) => {
                          onChange(value);
                        }}
                        value={value}
                      />
                    )}
                  />
                </div>
              )}
              <div />
            </div>
          </div>

          {/*Congress: Categories*/}
          {isCongressAlias && (
            <div className="formSection">
              <label style={{ marginTop: "2rem" }}>
                {t("event.categories")}
                <HoverDesc description="event.categories.desc" />
              </label>

              <div className={"comparisonView"}>
                {/*Original*/}
                <div>
                  <Controller
                    name={`ndtrcEntry.trcItemCategories.types`}
                    control={control}
                    defaultValue={[]}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <div
                          className={"categorySelect"}
                          style={{ width: "initial" }}
                        >
                          <CategorySelectWidget
                            onChange={onChange}
                            value={value}
                            entityType={watchedEntityType}
                            disabled={true}
                          />
                        </div>
                      );
                    }}
                  />
                </div>

                {/*Alias*/}
                <div>
                  <Controller
                    name={`${version}.trcItem.trcItemCategories.types`}
                    control={control}
                    defaultValue={[]}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <div
                          className={"categorySelect"}
                          style={{ width: "initial" }}
                        >
                          <CategorySelectWidget
                            onChange={onChange}
                            value={value}
                            entityType={watchedAliasEntityType}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/*Congress: Media*/}
          {isCongressAlias && (
            <div className="formSection">
              <label>
                Media
                <HoverDesc description="event.media.desc" />
              </label>

              <div className={"comparisonView"}>
                {/*Original*/}
                <div>
                  <Controller
                    control={control}
                    name={`ndtrcEntry.files`}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <MediaWidget
                        mediaArray={value}
                        onChange={onChange}
                        readonly={true}
                      />
                    )}
                    defaultValue={[]}
                  />
                </div>

                {/*Alias*/}
                <div>
                  <Controller
                    control={control}
                    name={`${version}.trcItem.files`}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <MediaWidget mediaArray={value} onChange={onChange} />
                    )}
                    defaultValue={[]}
                  />
                </div>
              </div>
            </div>
          )}

          {/*Congress: Properties*/}
          {isCongressAlias && props.myPermissions.mayEditAdvancedProperties && (
            <div className="formSection">
              <label>{t("item.properties")}</label>

              {/*Original*/}
              <div className={"comparisonView"}>
                <div>
                  <PropertiesWidget
                    properties={watchedCategories || []}
                    manualSave={() => {}}
                    lang={props.primaryLanguage}
                    readonly={true}
                    langs={props.intItem.translations?.availableLanguages}
                  />
                </div>

                {/*Alias*/}
                <div>
                  <PropertiesWidget
                    properties={watchedAliasCategories || []}
                    manualSave={(newProperties) => {
                      const extraData: any = {};
                      extraData[version] = {
                        trcItem: {
                          trcItemCategories: {
                            categories: newProperties,
                          },
                        },
                      };

                      // TODO: Remove timeout
                      setTimeout(() => {
                        props.localSaveTrigger(extraData);
                      }, 100);
                    }}
                    lang={props.primaryLanguage}
                    langs={props.intItem.translations?.availableLanguages}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </form>
    </div>
  );
};

export default ItemFormAlias;
