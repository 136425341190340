import React from "react";
import { ExtLangString } from "models/Ndtrc";
import LangInput from "./LangInput";
import { Lang, langMetadata } from "models/Lang";
import { Twemoji } from "helpers";
import Select from "react-select";
import classes from "./LangInput.module.scss";
import { Dropdown } from "components/Dropdown";
import { useTranslation } from "react-i18next";
import { AiFillDelete } from "react-icons/ai";

const DropdownIndicator = () => (
  <div className={classes.DropdownIndicator}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

const selectStyles = {
  control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

// NOTE: uses ExtLangString instead of ExtLabelLangString.
// If other type is needed, convert provided output in the higher level change function.
const MultiLangInput = (props: {
  value: ExtLangString[];
  onChange: (value: ExtLangString[]) => any;
  disabled?: boolean;
  primaryLang?: Lang;
}) => {
  const { t } = useTranslation();

  const [addFieldDropdownOpen, setAddFieldDropdownOpen] = React.useState(false);

  const langOptions = Object.values(Lang)
    .filter((lang: Lang) => {
      return !props.value.some((langString: ExtLangString) => {
        return langString.lang === lang;
      });
    })
    .map((lang: Lang) => {
      return {
        value: lang,
        label: (
          <>
            <Twemoji>{langMetadata[lang]?.flag}</Twemoji> {lang}
          </>
        ),
      };
    });

  const changeVal = (newLangString: ExtLangString) => {
    // Check if langString already exists
    if (
      props.value.find(
        (langString: ExtLangString) => langString.lang === newLangString.lang
      )
    ) {
      const newArray = props.value.map((langString: ExtLangString) => {
        if (langString.lang === newLangString.lang) {
          langString.text = newLangString.text;
        }
        return langString;
      });
      props.onChange(newArray);
    } else {
      // If not, create:
      props.onChange([...props.value, newLangString]);
    }
  };

  const remVal = (lang: Lang) => {
    if (
      !props.value.find((langString: ExtLangString) => langString.lang === lang)
    )
      return;

    const newArray = props.value.filter(
      (langString: ExtLangString) => langString.lang !== lang
    );
    props.onChange(newArray);
  };

  // If primaryLang is set, make sure it is the first in the list
  React.useEffect(() => {
    if (
      props.primaryLang &&
      !props.value.some((l) => l.lang === props.primaryLang)
    ) {
      changeVal({ lang: props.primaryLang, text: "" });
    }
  }, [props.value, props.primaryLang, changeVal]);

  return (
    <div className={classes.MultiLangInputWrapper}>
      <div className={classes.multiInputs}>
        {props.value.map((langString: ExtLangString, index: number) => (
          <div key={langString.lang} className={classes.multiInput}>
            <LangInput
              key={langString.lang}
              onChange={(newVal) => {
                changeVal({ lang: langString.lang, text: newVal });
              }}
              value={langString.text}
              lang={langString.lang}
              disabled={props.disabled}
            />
            {!props.disabled && langString.lang !== props.primaryLang && (
              <div className={classes.deleteButton}>
                <AiFillDelete
                  onClick={() => {
                    remVal(langString.lang);
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      {!props.disabled && langOptions.length > 0 && (
        <Dropdown
          isOpen={addFieldDropdownOpen}
          onClose={() => {
            setAddFieldDropdownOpen(!addFieldDropdownOpen);
          }}
          target={
            <div
              className={`${classes.EventFormLangTab}`}
              key={"new"}
              onClick={() => {
                setAddFieldDropdownOpen(!addFieldDropdownOpen);
              }}
            >
              + {t("form.addTranslation")}
            </div>
          }
        >
          <div className={classes.dropdownContents}>
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              // @ts-ignore
              onChange={(selection: { value: string; label: string }) => {
                if (selection) {
                  changeVal({ lang: selection.value as Lang, text: "" });
                }
                setAddFieldDropdownOpen(false);
              }}
              options={langOptions as any} // Any is needed to allow a component rather than text to be passed
              placeholder="Voeg taal toe..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={null}
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default MultiLangInput;
