import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { CategoryValue, ExtProperty } from "../../../models/Ndtrc/Ndtrc";
import {
  getObjectArrayByCategoryIds,
  getPropertyNameById,
  getPropertyObjectDigits,
  getRemainingCategoryOptions,
} from "helpers";
import {
  Option,
  Property,
  PropertyCategory,
  PropertyDatatype,
} from "../../../models/Ndtrc/PropertiesSource";
import classes from "./PropertiesWidget.module.scss";
import Select from "react-select";
import { SingleSelectOption } from "../../../models/SelectOption/SelectOption";
import _ from "lodash";
import { AiFillDelete, AiFillWarning } from "react-icons/ai";
import BooleanInputPopover from "../../popovers/BooleanInputPopover/BooleanInputPopover";
import PopupButton from "../../buttons/PopupButton/PopupButton";
import { useTranslation } from "react-i18next";
import { IoMdAddCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import { Lang } from "../../../models/Lang/Lang";
import { BiHide } from "react-icons/bi";
import LangInput from "../inputs/LangInput/LangInput";
import { ButtonType } from "../../buttons";

const debug = false;

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: 240,
    width: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const PropertiesWidget = (props: {
  properties: ExtProperty[];
  manualSave: (properties: ExtProperty[] | any) => any;
  lang: Lang;
  readonly?: boolean;
  exclude?: string[];
  include?: string[];
  expandProperties?: boolean;
  langs?: Lang[];
}) => {
  const { t } = useTranslation();
  const [propertyTemplates, setPropertyTemplates] = useState<
    PropertyCategory[] | null
  >(null);
  const [newDropdownOpen, setNewDropdownOpen] = useState(false);
  const [addCategoryOptions, setAddCategoryOptions] = useState<
    SingleSelectOption[]
  >([]);
  const langs =
    props.langs !== undefined ? props.langs : [Lang.NL, Lang.EN, Lang.DE];

  const [showProperties, setShowProperties] = useState(
    props.expandProperties !== undefined ? props.expandProperties : false
  );

  const manualSave = (id: string, newData?: ExtProperty) => {
    let newProperties: ExtProperty[];
    if (newData) {
      if (props.properties.find((prop) => prop.catid === id)) {
        // Replace in array
        newProperties = props.properties.map((prop) => {
          return prop.catid === id ? newData : prop;
        });
      } else {
        // Add to array
        newProperties = _.cloneDeep(props.properties);
        newProperties.push(newData);
      }
    } else {
      // Delete from array
      newProperties = props.properties.filter((prop) => prop.catid !== id);
    }

    props.manualSave(newProperties);
  };

  const removeCategory = (catId: string) => {
    // Remove category listing
    setPropertyTemplates(
      propertyTemplates?.filter((cat) => cat.id !== catId) || null
    );
    // Remove category items from data structure
    let newProperties: ExtProperty[] = props.properties.filter(
      (prop) => getPropertyObjectDigits(prop.catid)?.[0] !== parseInt(catId)
    );
    props.manualSave(newProperties);
  };

  const addCategory = (catId: string) => {
    const newCat = getObjectArrayByCategoryIds([parseInt(catId)]);
    if (!newCat || !propertyTemplates) {
      return;
    }
    const newPropertyTemplates = propertyTemplates
      .concat(newCat)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    setPropertyTemplates(newPropertyTemplates);

    const reverseCats = getRemainingCategoryOptions(
      newPropertyTemplates.map((cat) => parseInt(cat.id))
    );
    setAddCategoryOptions(reverseCats);
  };

  const computedPropTemplatesDigits: number[] | null = useMemo(() => {
    if (!propertyTemplates || !props.properties) return null;

    let r = props.properties?.map((property) => {
      const digits: number[] | null = getPropertyObjectDigits(property.catid);
      if (!digits) {
        throw new Error("Unknown property attached to this object");
      }
      return digits[0];
    });
    if (!r) return null;

    return r.concat(
      propertyTemplates.map((cat) => {
        return getPropertyObjectDigits(cat.id)?.[0] || -1;
      })
    );
  }, [props.properties, propertyTemplates]);

  useEffect(() => {
    // Determine unique head categories to retrieve
    const propertyCategories = Array.from(new Set(computedPropTemplatesDigits));

    // For each property category, render all items
    // Link with data currently available in data structure
    const newPropertyTemplates = getObjectArrayByCategoryIds(
      propertyCategories
    )?.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    setPropertyTemplates((prevTemplates) => {
      if (!newPropertyTemplates && !prevTemplates) {
        return null;
      }
      if (newPropertyTemplates === undefined) {
        return null;
      }
      // Only update if newTemplates is different from prevTemplates
      if (
        JSON.stringify(prevTemplates) !== JSON.stringify(newPropertyTemplates)
      ) {
        return newPropertyTemplates;
      }
      return prevTemplates;
    });

    const reverseCats = getRemainingCategoryOptions(propertyCategories);
    setAddCategoryOptions(reverseCats);
  }, [computedPropTemplatesDigits]);

  // Note: does not allow turning off properties view again
  // Otherwise would require good testing of saving behaviour on hide
  if (!showProperties && !props?.readonly) {
    return (
      <PopupButton
        action={() => {
          setShowProperties(true);
        }}
      >
        {t("form.seeEditAllProperties")}
      </PopupButton>
    );
  }

  const myProperties =
    propertyTemplates &&
    propertyTemplates?.map((cat, index0) => {
      if (props.exclude && props.exclude?.includes(cat.id)) {
        return null;
      }
      if (props.include && !props.include?.includes(cat.id)) {
        return null;
      }
      const properties = cat.properties.map((property, index) => {
        // Check if property already in filled in items
        let filledInProp =
          props.properties?.find(
            (filledInProp) => filledInProp.catid === property.id
          ) || null;

        if (filledInProp === null && props?.readonly) {
          return null;
        }

        switch (property.datatype) {
          case PropertyDatatype.yesno:
            return (
              <YesNoProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          case PropertyDatatype.yes:
            return (
              <YesProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          case PropertyDatatype.break:
            return (
              <div key={index} style={{ gridColumn: "1 / -1" }}>
                <hr />
              </div>
            );
          case PropertyDatatype.integer:
            return (
              <NumericalProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          case PropertyDatatype.freetext:
            return (
              <React.Fragment key={index}>
                <div>
                  {langs.map((lang) => {
                    return (
                      <FreetextProp
                        key={lang}
                        propTemplate={property}
                        propData={filledInProp}
                        manualSave={manualSave}
                        lang={lang}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            );
          case PropertyDatatype.choice:
            return (
              <ChoiceProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          case PropertyDatatype.multichoice:
            return (
              <MultichoiceProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          case PropertyDatatype.date:
            return (
              <DateProp
                key={index}
                propTemplate={property}
                propData={filledInProp}
                manualSave={manualSave}
                disabled={props?.readonly}
              />
            );
          default:
            console.error("Unknown property type", property.datatype);
            return (
              <div className={property.datatype}>
                {getPropertyNameById(property.id)} {debug && `(${property.id})`}
                : {filledInProp?.value || "NOT FILLED IN"}
              </div>
            );
        }
      });
      return (
        <div key={index0}>
          <h3>
            {cat.name}{" "}
            {!props?.readonly && props.include === undefined && (
              <BooleanInputPopover
                onBooleanSubmit={(deletion) => {
                  if (deletion) removeCategory(cat.id);
                }}
                prompt={t("form.removePropertyPrompt")}
                type={"danger"}
              >
                {(setPopover: any, onButtonClickHandler: any) => (
                  <span ref={setPopover}>
                    <AiFillDelete
                      className={`${classes.icon} ${classes.danger}`}
                      onClick={onButtonClickHandler}
                    />
                  </span>
                )}
              </BooleanInputPopover>
            )}
          </h3>
          <div className={classes.propertytwocolumns}>{properties}</div>
        </div>
      );
    });

  return (
    <div>
      {!props?.readonly && props.include === undefined && (
        <div className={classes.propertyButtons}>
          <Dropdown
            isOpen={newDropdownOpen}
            onClose={() => {
              setNewDropdownOpen(!newDropdownOpen);
            }}
            target={
              <PopupButton
                action={() => {
                  setNewDropdownOpen(!newDropdownOpen);
                }}
                type={ButtonType.Success}
              >
                <IoMdAddCircle /> {t("form.addPropertyCategory")}
              </PopupButton>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              // @ts-ignore
              onChange={(selection: { value: string; label: string }) => {
                if (selection) {
                  addCategory(selection.value);
                }
              }}
              options={addCategoryOptions} // Any is needed to allow a component rather than text to be passed
              placeholder={t("form.addPropertyCategoryPrompt")}
              styles={selectStyles}
              tabSelectsValue={false}
              value={null}
            />
          </Dropdown>
          <PopupButton
            type={ButtonType.Gray4}
            action={() => {
              setShowProperties(false);
            }}
          >
            <BiHide /> {t("form.hideEditAllProperties")}
          </PopupButton>
        </div>
      )}
      {props.include !== undefined && (
        <div className={classes.propertyButtons}>
          <Dropdown
            isOpen={newDropdownOpen}
            onClose={() => {
              setNewDropdownOpen(!newDropdownOpen);
            }}
            target={
              <PopupButton
                action={() => {
                  setNewDropdownOpen(!newDropdownOpen);
                }}
                type={ButtonType.Success}
              >
                <IoMdAddCircle /> {t("form.addPropertyCategory")}
              </PopupButton>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              // @ts-ignore
              onChange={(selection: { value: string; label: string }) => {
                if (selection) {
                  addCategory(selection.value);
                }
              }}
              options={addCategoryOptions} // Any is needed to allow a component rather than text to be passed
              placeholder={t("form.addPropertyCategoryPrompt")}
              styles={selectStyles}
              tabSelectsValue={false}
              value={null}
            />
          </Dropdown>
        </div>
      )}

      {myProperties && myProperties.length > 0 ? (
        myProperties
      ) : (
        <div className={classes.noPreview}>
          <AiFillWarning />
          <span>
            <strong>{t("form.noPropsToShow")}</strong>
            {!props?.readonly && ": " + t("form.suggestAddProps")}
          </span>
        </div>
      )}
    </div>
  );
};

const YesProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !(props.propData?.value === "True" || props.propData?.value === "true")
          ? classes.inactive
          : classes.active
      }`}
    >
      <label>
        <input
          type="checkbox"
          value={props.propData?.value ? "True" : "False"}
          onChange={() => {
            const val = !props.propData?.value;
            props.manualSave(
              props.propTemplate.id,
              val
                ? {
                    catid: props.propTemplate.id,
                    valueid: null,
                    value: "True",
                    datatype: props.propTemplate.datatype,
                  }
                : undefined
            );
          }}
          checked={
            props.propData?.value === "True" || props.propData?.value === "true"
          }
          disabled={props?.disabled}
        />
        {getPropertyNameById(props.propTemplate.id)}{" "}
        {debug && `(${props.propTemplate.id})`}
      </label>
    </div>
  );
};

const YesNoPropOptions: SingleSelectOption[] = [
  { value: "True", label: "Ja" },
  { value: "False", label: "Nee" },
];

const YesNoProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  // Determine state
  const value = props.propData?.value
    ? props.propData?.value === "True" || props.propData?.value === "true"
      ? YesNoPropOptions[0]
      : YesNoPropOptions[1]
    : null;

  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !value ? classes.inactiveInput : classes.active
      }`}
    >
      <label>
        <span>
          {getPropertyNameById(props.propTemplate.id)}{" "}
          {debug && `(${props.propTemplate.id})`}
        </span>
        <Select
          value={value}
          isClearable
          options={YesNoPropOptions}
          menuPlacement={"auto"}
          placeholder={"(niet aangegeven)"}
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 2 };
            },
          }}
          // @ts-ignore // Is correct type
          onChange={(item: SingleSelectOption | null) => {
            props.manualSave(
              props.propTemplate.id,
              item
                ? {
                    catid: props.propTemplate.id,
                    valueid: null,
                    value: item.value,
                    datatype: props.propTemplate.datatype,
                  }
                : undefined
            );
          }}
          isDisabled={props?.disabled}
        />
      </label>
    </div>
  );
};

// TODO: Actually parse numbers rather than work with strings
const NumericalProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  const [val, setVal] = useState(props.propData?.value || "");

  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !(val && val.trim().length > 0) ? classes.inactive : classes.active
      }`}
    >
      <label>
        {getPropertyNameById(props.propTemplate.id)}{" "}
        {debug && `(${props.propTemplate.id})`}
        <input
          type="number"
          value={val}
          onChange={(event) => {
            setVal(event.target.value);
          }}
          onBlur={(event) => {
            const val = event.target.value;
            if (val && val.trim().length > 0) {
              props.manualSave(props.propTemplate.id, {
                catid: props.propTemplate.id,
                valueid: null,
                value: val,
                datatype: props.propTemplate.datatype,
              });
            } else {
              props.manualSave(props.propTemplate.id, undefined);
            }
          }}
          disabled={props?.disabled}
        />
      </label>
    </div>
  );
};

const FreetextProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  lang: Lang;
  disabled?: boolean;
}) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(
      props.propData?.categoryTranslations?.find(
        (trans) => trans.lang === props.lang
      )?.value || ""
    );
  }, [props.lang, props.propData]);

  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !(val && val.trim().length > 0) ? classes.inactive : classes.active
      }`}
    >
      <label>
        {getPropertyNameById(props.propTemplate.id)} ({props.lang})
        {debug && `(${props.propTemplate.id})`}
        <LangInput
          value={val}
          onChange={(newVal) => {
            setVal(newVal);
          }}
          lang={props.lang}
          onBlur={(event) => {
            const val = event.target.value;
            let categoryTranslations =
              _.cloneDeep(props.propData?.categoryTranslations) || [];
            if (val && val.trim().length > 0) {
              const newCatTrans = {
                value: val,
                lang: props.lang,
              };

              let hasFound = false;
              categoryTranslations = categoryTranslations.map((trans) => {
                if (trans.lang === props.lang) {
                  hasFound = true;
                  return newCatTrans;
                } else {
                  return trans;
                }
              });

              // Add mew element to array if not found yet
              if (!hasFound) {
                categoryTranslations.push(newCatTrans);
              }
            } else {
              // Remove current lang from categoryTranslations array
              categoryTranslations = categoryTranslations.filter((trans) => {
                return trans.lang !== props.lang;
              });
            }

            // If empty categoryTranslations, return undefined
            if (categoryTranslations.length === 0) {
              props.manualSave(props.propTemplate.id, undefined);
              return;
            }

            props.manualSave(props.propTemplate.id, {
              catid: props.propTemplate.id,
              valueid: null,
              value: categoryTranslations[0].value,
              datatype: props.propTemplate.datatype,
              categoryTranslations: categoryTranslations,
            });
          }}
          disabled={props?.disabled}
        />
      </label>
    </div>
  );
};

const ChoiceProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  const options: SingleSelectOption[] = (
    props.propTemplate.options as Option[]
  ).map((option) => {
    return {
      label: option.name,
      value: option.id,
    };
  });

  let value;
  if (props.propData && props.propData.valueid) {
    const opt = options.find((opt) => opt.value === props.propData?.valueid);
    value = opt || null;
  } else {
    value = null;
  }

  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !value ? classes.inactiveInput : classes.active
      }`}
    >
      <label>
        <span>
          {getPropertyNameById(props.propTemplate.id)}{" "}
          {debug && `(${props.propTemplate.id})`}
        </span>
        <Select
          value={value}
          isClearable
          options={options}
          menuPlacement={"auto"}
          placeholder={"(niet aangegeven)"}
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 2 };
            },
          }}
          // @ts-ignore // Is correct type
          onChange={(item: any) => {
            props.manualSave(
              props.propTemplate.id,
              item
                ? {
                    catid: props.propTemplate.id,
                    valueid: item.value,
                    datatype: props.propTemplate.datatype,
                  }
                : undefined
            );
          }}
          isDisabled={props?.disabled}
        />
      </label>
    </div>
  );
};

const MultichoiceProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  // console.log(props.propTemplate, props.propTemplate.options); // DEBUG

  if (!props.propTemplate.options) {
    // TODO: translation
    return (
      <div>
        Geen opties gevonden voor {props.propTemplate.name}. Heb je deze nodig,
        neem dan contact op met support en noem het id {props.propTemplate.id}
      </div>
    );
  }

  const options: SingleSelectOption[] = (
    props.propTemplate.options as Option[]
  ).map((option) => {
    return {
      label: option.name,
      value: option.id,
    };
  });

  let values: SingleSelectOption[] =
    props.propData?.categoryvalues.map((catVal) => {
      return {
        value: catVal.catid,
        label: getPropertyNameById(catVal.catid) || catVal.catid,
      };
    }) || [];

  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        values.length < 1 ? classes.inactiveInput : classes.active
      }`}
    >
      <label>
        <span>
          {getPropertyNameById(props.propTemplate.id)}{" "}
          {debug && `(${props.propTemplate.id})`}
        </span>
        <Select
          value={values}
          isClearable
          isMulti
          options={options}
          menuPlacement={"auto"}
          placeholder={"(niet aangegeven)"}
          styles={{
            menu: (provided, state) => {
              return { ...provided, zIndex: 2 };
            },
          }}
          // @ts-ignore // Is correct type
          onChange={(items: any) => {
            const catValues: CategoryValue[] = items.map(
              (opt: SingleSelectOption) => {
                return { catid: opt.value };
              }
            );
            props.manualSave(
              props.propTemplate.id,
              items.length > 0
                ? {
                    catid: props.propTemplate.id,
                    categoryvalues: catValues,
                    datatype: props.propTemplate.datatype,
                  }
                : undefined
            );
          }}
          isDisabled={props?.disabled}
        />
      </label>
    </div>
  );
};

const DateProp = (props: {
  propTemplate: Property;
  propData: ExtProperty | null;
  manualSave: (id: string, formData: FormData | any) => any;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`${classes[props.propTemplate.datatype]} ${
        !props.propData?.value ? classes.inactiveInput : classes.active
      }`}
    >
      <label>
        <span>
          {getPropertyNameById(props.propTemplate.id)}{" "}
          {debug && `(${props.propTemplate.id})`}
        </span>
        <DatePicker
          selected={
            props.propData?.value ? new Date(props.propData?.value) : null
          }
          onChange={(date) => {
            props.manualSave(
              props.propTemplate.id,
              date
                ? {
                    catid: props.propTemplate.id,
                    value: date,
                    datatype: props.propTemplate.datatype,
                  }
                : undefined
            );
          }}
          locale="nl"
          dateFormat="dd-MM-yyyy"
          disabled={props?.disabled}
        />
      </label>
    </div>
  );
};

const DropdownIndicator = () => (
  <div className={classes.DropdownIndicator}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

const Dropdown = (props: {
  children: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
  disabled?: boolean;
}) => (
  <div className={classes.Dropdown}>
    {props.target}
    {props.isOpen ? <Menu>{props.children}</Menu> : null}
    {props.isOpen ? <Blanket onClick={props.onClose} /> : null}
  </div>
);

const Menu = (props: any) => {
  return <div className={classes.Menu} {...props} />;
};
const Blanket = (props: any) => <div className={classes.Blanket} {...props} />;

export default PropertiesWidget;
