import React from "react";
import classes from "./LangInput.module.scss";
import { Twemoji } from "helpers";
import { Lang, langMetadata } from "models/Lang";

const LangInput = (props: {
  value: string;
  onChange: (value: string) => any;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;
  lang: Lang;
  disabled?: boolean;
}) => {
  return (
    <div className={classes.LangInput}>
      <input
        type={props.disabled ? "hidden" : "text"}
        style={{ width: "100%" }}
        onChange={(event) => {
          props.onChange(event.currentTarget.value || "");
        }}
        value={props.value || ""}
        onBlur={props.onBlur}
      />
      {!props.disabled && (
        <span className={classes.langFlagEnd}>
          <Twemoji>{langMetadata[props.lang]?.flag}</Twemoji>
        </span>
      )}
      {props.disabled && (
        <span className={classes.readOnlySummary}>
          <Twemoji>{langMetadata[props.lang]?.flag}</Twemoji> {props.value}
        </span>
      )}
    </div>
  );
};

export default LangInput;
